import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as landingPageActions from '@redux/modules/landingPage'

import defaultFormState, { requiredFields } from '@models/landingPage'

import type { AppDispatch } from '@redux/store'
import type { ModuleState } from '@redux/modules/types'
import type { LandingPageModel } from '@models/types'

type UpdateLandingPageParams = {
  dispatch: AppDispatch,
  landingPage: LandingPageModel,
  landingPageParams: Partial<LandingPageModel>,
}

const updateLandingPage = (params: UpdateLandingPageParams) => {
  const { dispatch, landingPage, landingPageParams } = params
  const { updateLandingPage: updateFn } = landingPageActions

  const updatedParams = {
    id: landingPage.id,
    ...landingPageParams,
  }

  return dispatch(updateFn(updatedParams))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useLandingPageForm(
  landingPage: Partial<LandingPageModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const landingPageForm = useForm(
    defaultFormState,
    { entity: landingPage, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [landingPage.id, landingPage.cacheKey],
  )

  return {
    ...landingPageForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
}

function useLandingPage(initEntity: Partial<LandingPageModel> = {}, options: Options = {}) {
  const { performHttpRequests } = options

  const { entity: landingPage }: { entity: LandingPageModel } = useLatestEntity(initEntity, 'landingPages')
  const { id } = landingPage

  const dispatch = useDispatch()

  useReduxAction(
    'landingPages',
    'loadLandingPage',
    {
      entityId: id,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, loading, updating,
  } = useSelector(reduxState => reduxState.landingPages)

  return {
    callbacks: {
      updateLandingPage: (landingPageParams: Partial<LandingPageModel>) => (
        updateLandingPage({ landingPage, landingPageParams, dispatch })
      ),
    },
    creating,
    landingPage,
    loading,
    updating,
  }
}

export default useLandingPage
