import type { ValidationStatus } from '@models/campaignValidation'
import type { LandingPageModel } from '@models/landingPage'

export type Issue = {
  description: string,
  isCritical: boolean,
}

export type PreflightValidationSummary = {
  campaignStatus: ValidationStatus,
  facebookStatus: ValidationStatus,
  googleStatus: ValidationStatus,
  issues?: Issue[],
  productStatus: ValidationStatus,
  status: ValidationStatus,
}

export type AdCampaignSummary = {
  cancelled?: number,
  completed?: number,
  failed?: number,
  issues?: Issue[],
  live?: number,
  paused?: number,
  pending?: number,
  review?: number,
  scheduled?: number,
  status: string,
  total: number,
}

export type CampaignModel = {
  adTemplateId?: number,
  artworkTemplateGroupId?: number,
  assetGroupId?: number,
  cacheKey: number,
  campaignPlaybookGroupId?: number,
  campaignTypeId: number,
  clientId: number,
  createdAt: string,
  displayUrl?: string,
  extendedStatusId?: number,
  externalPlatform?: string,
  id: number,
  landingPageId?: number,
  lastModifiedAt: string,
  lastModifiedBy: string,
  name: string,
  orderItemId?: number,
  projectId: number,
  realbaseId?: number,
  referenceId?: number,
  requiredAt: string,
  statusId: string,
  targetingTemplateId?: number,
  userId?: number,
  videoUrl?: string,
  websiteUrl?: string,
  landingPage?: LandingPageModel,
  campaignSummary?: {
    facebook?: AdCampaignSummary,
    googleDisplay?: AdCampaignSummary,
    googleSearch?: AdCampaignSummary,
    preflightValidation?: PreflightValidationSummary,
    website?: {
      type: string,
      status: string,
    },
  },
}

export type CampaignRequestOptions = {
  include?: string | string[],
}

const state = {
  id: null,
  adTemplateId: null,
  assetGroupId: null,
  campaignPlaybookGroupId: null,
  campaignTypeId: null,
  clientId: null,
  displayUrl: '',
  extendedStatusId: null,
  landingPageId: null,
  landingPage: null,
  name: '',
  projectId: null,
  realbaseId: null,
  referenceId: null,
  requiredAt: '',
  statusId: null,
  targetingTemplateId: null,
  userId: null,
  videoUrl: '',
  websiteUrl: '',
}

export const requiredFields = [
  {
    key: 'requiredAt',
    validation: (requiredAt: string) => {
      if (!requiredAt){
        return false
      }

      const requiredDate = new Date(requiredAt).setHours(0, 0, 0, 0)
      const currentDate = new Date().setHours(0, 0, 0, 0)

      return requiredDate >= currentDate
    },
    invalidMessage: 'Invalid',
  },
]

export default state
