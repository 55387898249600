import React from 'react'
import classnames from 'classnames'
import {
  ButtonBack, ButtonNext, CarouselProvider, DotGroup, Slide, Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'

import { Box } from '@campaignhub/suit-theme'

import styles from './styles.module.scss'

interface CarouselProps {
  children: React.ReactElement[],
}

const Carousel = (props: CarouselProps) => {
  const { children } = props

  return (
    <CarouselProvider
      className={styles.carousel}
      infinite
      naturalSlideHeight={1}
      naturalSlideWidth={1}
      totalSlides={children.length}
    >
      <ButtonBack className={classnames(styles.navigationButton, styles.left)}>
        <span className={styles.inner}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      </ButtonBack>

      <Slider>
        {children.map((child, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Slide index={index} key={index}>
            <Box alignItems="center" justifyContent="center">
              {child}
            </Box>
          </Slide>
        ))}
      </Slider>

      <ButtonNext className={classnames(styles.navigationButton, styles.right)}>
        <span className={styles.inner}>
          <FontAwesomeIcon icon={faAngleRight} />
        </span>
      </ButtonNext>

      <Box justifyContent="center">
        <DotGroup />
      </Box>
    </CarouselProvider>
  )
}

export default Carousel
