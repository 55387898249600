import { Box, DashboardModule } from '@campaignhub/suit-theme'

import Carousel from '@components/Carousel'
import FacebookPreview from '@components/FacebookPreview'

import useAdAccountLinks from '@hooks/useAdAccountLinks'
import useArtworks from '@hooks/useArtworks'
import useCampaign from '@hooks/useCampaign'
import useCaptions from '@hooks/useCaptions'
import { useRelations } from '@hooks/useAdAccountLink'

interface FacebookAdsProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const FacebookAds = (props: FacebookAdsProps) => {
  const {
    campaignPayload: {
      campaign,
      loading,
    },
  } = props

  const artworksPayload = useArtworks({
    filters: {
      campaignId: campaign.id,
      height: 1200,
      width: 1200,
    },
  })

  const { filteredArtworks } = artworksPayload
  const artwork = filteredArtworks[0] || {}

  const captionsPayload = useCaptions({
    filters: {
      campaignId: campaign.id,
    },
  })

  const { filteredCaptions } = captionsPayload

  const adAccountLinksPayload = useAdAccountLinks({
    filters: {
      adAccountType: 'FacebookPage',
      linkableId: campaign.clientId,
      linkableType: 'Client',
    },
    performHttpRequests: true,
    requestOptions: {
      include: 'AdAccount',
    },
  })

  const { filteredAdAccountLinks } = adAccountLinksPayload
  const facebookPageLink = filteredAdAccountLinks[0] || {}

  const { adAccount } = useRelations(facebookPageLink)

  return (
    <DashboardModule
      contentBoxProps={{ alignItems: "center", justifyContent: "center", padding: "large" }}
      loading={loading}
      title="Facebook Ads"
    >
      {!loading && (
        <Box justifyContent="center" height="560px" width="550px">
          <Carousel>
            {[
              <FacebookPreview
                key="1"
                artwork={artwork}
                captions={filteredCaptions}
                facebookPage={adAccount}
              />,
              <FacebookPreview
                key="2"
                artwork={artwork}
                captions={filteredCaptions}
                facebookPage={adAccount}
              />,
            ]}
          </Carousel>
        </Box>
      )}
    </DashboardModule>
  )
}

export default FacebookAds