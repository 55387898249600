export type LandingPageModel = {
  cacheKey: number,
  firstCheckedAt?: string,
  id: number,
  isValidated: boolean,
  landingPageTypeId: string,
  lastCheckedAt?: string,
  referenceId: number,
  screenshotUrl?: string,
  url: string,
  vanityUrl?: string,
}

const state = {
  firstCheckedAt: '',
  id: null,
  isValidated: false,
  landingPageTypeId: '',
  lastCheckedAt: '',
  referenceId: null,
  screenshotUrl: '',
  url: '',
  vanityUrl: '',
}

export const requiredFields = []

export default state