import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faShare, faThumbsUp, faTimes } from '@fortawesome/pro-light-svg-icons'
import { faGlobeAfrica } from '@fortawesome/pro-solid-svg-icons'

import { Box, Image, Text } from '@campaignhub/suit-theme'

import systemColours from '@functions/colours'

import type {
  AdAccountModel,
  ArtworkModel,
  CaptionModel,
} from '@models/types'

interface FacebookPreviewProps {
  artwork: ArtworkModel,
  captions: CaptionModel[],
  facebookPage: AdAccountModel,
}

const FacebookPreview = (props: FacebookPreviewProps) => {
  const { artwork, captions, facebookPage } = props

  const { url } = artwork || {}
  const { name } = facebookPage || {}

  const descriptionCaptionText = captions?.find(caption => caption.type === 'Description')?.values?.[0]
  const headlineCaptionText = captions?.find(caption => caption.type === 'Headline')?.values?.[0]
  const textCaptionText = captions?.find(caption => caption.type === 'Text')?.values?.[0]

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="lineColor"
      flexDirection="column"
      width="320px"
    >
      <Box alignItems="center" justifyContent="space-between" paddingTop="10px" paddingX="10px">
        <Box>
          <Image
            boxProps={{ border: "1px solid", borderColor: "lineColor", marginRight: "medium" }}
            circle
            width="40px"
          />

          <Box flexDirection="column" justifyContent="center">
            <Text fontSize="xsmall" fontWeight={700} lineHeight="17px">{name}</Text>

            <Box alignItems="center" lineHeight="12px">
              <Text color="bodyFontLightColor" fontSize="xxsmall">
                Sponsored
              </Text>

              <Text color="bodyFontLightColor" fontWeight={100} marginX="2px">&#x2022;</Text>

              <FontAwesomeIcon color={systemColours.grey} icon={faGlobeAfrica} style={{ height: 10, width: 10 }} />
            </Box>
          </Box>
        </Box>

        <Text color="black">
          <FontAwesomeIcon icon={faTimes} style={{ height: 20, width: 20 }} />
        </Text>

        <Box flexDirection="column" alignItems="center" marginLeft="large" width="24px">
          {[1, 2, 3].map(key => <Text key={key} lineHeight={0.4}>&#x2022;</Text>)}
        </Box>
      </Box>

      <Text fontSize="xsmall" lineHeight="16px" marginY="medium" marginX="10px">
        {textCaptionText}
      </Text>

      <Image
        height="320px"
        style={{ borderTop: "2px solid #F5F6F8" }}
        url={url}
        width="100%"
      />

      <Box borderBottom="1px solid #ECEDEE" backgroundColor="#F7F8FA" padding="12px">
        <Box flexDirection="column" lineHeight="16px">
          <Text fontSize="xxsmall">domain.com.au</Text>

          <Text color="#1C2028" fontSize="small" fontWeight={700}>{headlineCaptionText}</Text>

          <Text fontSize="xsmall">{descriptionCaptionText}</Text>
        </Box>

        <Box alignItems="center" justifyContent="right">
          <Box
            alignItems="center"
            backgroundColor="#E2E5E9"
            borderRadius={5}
            height="36px"
            justifyContent="center"
            width="100px"
          >
            <Text fontSize="small" fontWeight={700}>Learn More</Text>
          </Box>
        </Box>
      </Box>

      <Box borderTop="2px solid #E6E7EA" marginTop="16px" marginX="10px" width="calc(100% - 20px)" />

      <Box
        justifyContent="space-around"
        marginX="10px"
        marginY="medium"
        width="calc(100% - 20px)"
      >
        <Box width="min-content">
          <FontAwesomeIcon color="#616770" icon={faThumbsUp} />
          <Text color="#616770" fontWeight={600} fontSize="xsmall" marginLeft="medium">Like</Text>
        </Box>

        <Box width="min-content">
          <FontAwesomeIcon color="#616770" icon={faMessage} />
          <Text color="#616770" fontWeight={600} fontSize="xsmall" marginLeft="medium">Comment</Text>
        </Box>

        <Box width="min-content">
          <FontAwesomeIcon color="#616770" icon={faShare} />
          <Text color="#616770" fontWeight={600} fontSize="xsmall" marginLeft="medium">Share</Text>
        </Box>
      </Box>
    </Box>
  )
}

export default FacebookPreview