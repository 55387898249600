import { formatDate } from '@campaignhub/javascript-utils'

import { DashboardModule, Form } from '@campaignhub/suit-theme'

import buildDataStoreItemSummary from '@functions/dataStoreItem'

import useAdQueues from '@hooks/useAdQueues'
import useCampaign, { useCampaignForm } from '@hooks/useCampaign'
import useCampaignTypes from '@hooks/useCampaignTypes'
import useDataStoreItems from '@hooks/useDataStoreItems'
import { useLandingPageForm } from '@hooks/useLandingPage'

interface CampaignDetailsProps {
  campaignFormPayload: ReturnType<typeof useCampaignForm>,
  campaignPayload: ReturnType<typeof useCampaign>,
  landingPageFormPayload: ReturnType<typeof useLandingPageForm>,
}

const CampaignDetails = (props: CampaignDetailsProps) => {
  const {
    campaignFormPayload,
    campaignPayload,
    landingPageFormPayload,
  } = props

  const {
    campaign,
    campaign: {
      projectId,
    },
    loading,
  } = campaignPayload

  const {
    entityState: {
      campaignTypeId,
      requiredAt,
    },
    errors,
    handlers: campaignHandlers,
  } = campaignFormPayload

  const {
    entityState: {
      url,
    },
    handlers: landingPageHandlers,
  } = landingPageFormPayload

  const { campaignTypes } = useCampaignTypes({ performHttpRequests: true })

  const { filteredDataStoreItems } = useDataStoreItems({
    filters: {
      projectId,
    },
  })

  const { PostCode: postCode } = buildDataStoreItemSummary(filteredDataStoreItems)

  const formattedRequiredAt = requiredAt
    ? formatDate(new Date(requiredAt).toISOString(), 'ISO8601', 'yyyy-MM-dd')
    : ''

  const { filteredAdQueues } = useAdQueues({
    filters: {
      campaignId: campaign.id,
    },
  })

  const maxDuration = filteredAdQueues.reduce((acc, adQueue) => {
    const { duration } = adQueue
    if (duration > acc) acc = duration
    return acc
  }, 0)

  return (
    <DashboardModule loading={loading} title="Campaign Details">
      <Form padding="large">
        <Form.Field label="Campaign Type">
          <select name="campaignTypeId" value={campaignTypeId || ""} {...campaignHandlers}>
            <option value="">None</option>
            {campaignTypes.map(campaignType => (
              <option key={campaignType.id} value={campaignType.id}>{campaignType.displayName}</option>
            ))}
          </select>
        </Form.Field>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.requiredAt} label="* Start Date">
            <input
              name="requiredAt"
              type="date"
              value={formattedRequiredAt}
              {...campaignHandlers}
            />
          </Form.Field>

          <Form.Field label="Duration">
            <input
              disabled
              type="text"
              value={maxDuration}
            />
          </Form.Field>
        </Form.Row>

        <Form.Field label="Post Code" marginTop="large">
          <input
            disabled
            type="text"
            value={postCode}
          />
        </Form.Field>

        <Form.Field label="* Website URL" marginTop="large">
          <input
            name="url"
            type="text"
            value={url}
            {...landingPageHandlers}
          />
        </Form.Field>
      </Form>
    </DashboardModule>
  )
}

export default CampaignDetails