import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['productLines']

type UseProductLinesOptions = {
  performHttpRequests?: boolean,
}

function useProductLines(options: UseProductLinesOptions) {

  const {
    performHttpRequests = false,
  } = options || {}

  const {
    updatedEntities: { productLines: productLinesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingProductLines } = useReduxAction(
    'productLines',
    'loadProductLines',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const entityProductLines = useSelector(reduxState => reduxState.entities.productLines)

  const productLines = useMemo(() => Object.values(entityProductLines), [productLinesUpdatedAt])

  return {
    loading: loadingProductLines,
    productLines,
  }
}

export default useProductLines
