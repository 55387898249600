import { Box, Text } from '@campaignhub/suit-theme'

import buildDataStoreItemSummary from '@functions/dataStoreItem'

import useCampaign, { useRelations as useCampaignRelations } from '@hooks/useCampaign'
import useDataStoreItems from '@hooks/useDataStoreItems'
import { useRelations as useOrderItemRelations } from '@hooks/useOrderItem'
import { useRelations as usePriceListItemRelations } from '@hooks/usePriceListItem'

const generateAddressString = (dataStoreItemSummary: { [key: string]: string }) => {
  const {
    PostCode,
    State,
    Street,
    StreetAddress,
    Suburb,
  } = dataStoreItemSummary

  let address = ''
  if (StreetAddress) address += `${StreetAddress}`
  if (Street) address += ` ${Street}`
  if (Suburb) address += `, ${Suburb}`
  if (State) address += `, ${State.toUpperCase()}`
  if (PostCode) address += ` - ${PostCode}`

  return address
}

interface CampaignInfoProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const CampaignInfo = (props: CampaignInfoProps) => {
  const {
    campaignPayload: {
      campaign,
      campaign: {
        projectId,
      },
    },
  } = props

  const { filteredDataStoreItems } = useDataStoreItems({
    filters: {
      projectId,
    },
  })

  const dataStoreItemSummary = buildDataStoreItemSummary(filteredDataStoreItems)

  const address = generateAddressString(dataStoreItemSummary)

  const { orderItem } = useCampaignRelations(campaign)
  const { priceListItem } = useOrderItemRelations(orderItem)
  const { product } = usePriceListItemRelations(priceListItem)

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      marginBottom="large"
      padding="large"
    >
      <Text fontSize="medium" letterSpacing="0.3px" lineHeight={1.5}>
        {address}
      </Text>

      <Text color="bodyFontLightColor" fontSize="small" letterSpacing="0.3px" lineHeight={1.43}>
        {`${product.name || ''} (AIM #${campaign.id})`}
      </Text>
    </Box>
  )
}

export default CampaignInfo