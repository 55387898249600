import { useContext } from 'react'

import { launchModal, sortArrayBy } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultFormState, { requiredFields } from '@models/campaign'

import * as campaignActions from '@redux/modules/campaign'
import * as campaignValidationActions from '@redux/modules/campaignValidation'
import type { GenerateArtworksForCampaignOptions } from '@redux/modules/campaign'

import type { EntityOptions, ModuleState } from '@redux/modules/types'
import type { AppDispatch } from '@redux/store'

import type { CampaignModel, CampaignRequestOptions, PropertyModel } from '@models/types'

export const generateUrls = (campaign?: Partial<CampaignModel>) => {
  const { id } = campaign || {}

  return {
    campaignActivitiesUrl: `#/campaigns/${id}/activities`,
    campaignDetailUrl: `#/campaigns/${id}/details`,
    campaignEnquiriesUrl: `#/campaigns/${id}/enquiries`,
    campaignOverviewUrl: `#/campaigns/${id}/overview`,
    campaignReportingUrl: `#/campaigns/${id}/reporting`,
    campaignsIndexUrl: '#/campaigns',
    systemAdminOrderApproval: `#/orders/${id}/approval`,
    systemAdminOrderCampaigns: `#/orders/${id}/campaigns`,
    systemAdminOrderCreative: `#/orders/${id}/creative`,
    systemAdminOrderOverview: `#/orders/${id}/overview`,
    systemAdminOrderReporting: `#/orders/${id}/reporting`,
  }
}

type UpdateCampaignParams = {
  campaign: CampaignModel,
  campaignParams: Partial<CampaignModel>,
  dispatch: AppDispatch,
  requestOptions?: CampaignRequestOptions,
}

const updateCampaign = (params: UpdateCampaignParams) => {
  const {
    campaign, campaignParams, dispatch, requestOptions,
  } = params
  const { updateCampaign: updateFn } = campaignActions

  const updatedParams = {
    id: campaign.id,
    ...campaignParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type PatchCampaignModel = Partial<Pick<CampaignModel, 'campaignTypeId' | 'extendedStatusId' | 'name' | 'requiredAt' | 'statusId' | 'videoUrl'>>

type PatchCampaignParams = {
  campaign: CampaignModel,
  campaignParams: PatchCampaignModel,
  dispatch: AppDispatch,
  requestOptions?: CampaignRequestOptions,
}

const patchCampaign = (params: PatchCampaignParams) => {
  const {
    campaign,
    campaignParams,
    dispatch,
    requestOptions,
  } = params

  const { patchCampaign: patchFn } = campaignActions

  const patchedParams = {
    id: campaign.id,
    ...campaignParams,
  }

  return dispatch(patchFn(patchedParams, requestOptions))
}

type ValidateCampaignParams = {
  campaign: CampaignModel,
  dispatch: AppDispatch,
}

const validateCampaign = (params: ValidateCampaignParams) => {
  const { campaign, dispatch } = params
  const { validateCampaign: validateFn } = campaignValidationActions

  return dispatch(validateFn(campaign))
}

type GenerateArtworksForCampaignParams = {
  campaign: CampaignModel,
  dispatch: AppDispatch,
  requestOptions?: GenerateArtworksForCampaignOptions,
}

const generateArtworksForCampaign = (params: GenerateArtworksForCampaignParams) => {
  const { campaign, dispatch, requestOptions } = params
  const { generateArtworksForCampaign: generateArtworksForCampaignFn } = campaignActions

  return dispatch(generateArtworksForCampaignFn(campaign.id, requestOptions))
}

export const useRelations = (campaign: CampaignModel) => {
  const {
    adTemplateId,
    assetGroupId,
    campaignTypeId,
    id,
    landingPageId,
    orderItemId,
    projectId,
  } = campaign

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    adTemplates, artworks, assets, campaignTypes, landingPages, orderItems, projects, properties,
  } = entities

  const adTemplate = adTemplateId && adTemplates[adTemplateId] ? adTemplates[adTemplateId] : {}

  const campaignArtworks = Object.values(artworks).filter(artwork => (
    artwork.campaignId == id
  ))

  const campaignType = campaignTypes[campaignTypeId] || {}

  const campaignImages = Object.values(assets).filter(asset => (
    asset.category === 'Image' && asset.assetGroupId === assetGroupId
  ))

  const sortedCampaignImages = sortArrayBy(campaignImages, 'asc', ({ sort }) => (sort || Infinity))

  const landingPage = landingPageId && landingPages[landingPageId] ? landingPages[landingPageId] : {}

  const orderItem = orderItemId && orderItems[orderItemId] ? orderItems[orderItemId] : {}

  const project = projectId && projects[projectId] ? projects[projectId] : {}

  const property: PropertyModel = Object.values(properties).find(p => p.campaignId === id) || {}

  return {
    adTemplate,
    artworks: campaignArtworks,
    campaignType,
    coverImage: sortedCampaignImages[0] || {},
    images: sortedCampaignImages,
    landingPage,
    orderItem,
    project,
    property,
  }
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCampaignForm(
  campaign: Partial<CampaignModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const campaignForm = useForm(
    defaultFormState,
    {
      entity: campaign,
      requiredFields: [...requiredFields, ...customRequiredFields],
      validateOn,
    },
    [campaign.id, campaign.cacheKey],
  )

  return {
    ...campaignForm,
  }
}

interface UseCampaignOptions {
  performHttpRequests?: boolean,
  requestOptions?: EntityOptions,
}

function useCampaign(initEntity: Partial<CampaignModel>, options?: UseCampaignOptions) {
  const { performHttpRequests = false, requestOptions } = options || {}

  const { entity: campaign }: { entity: CampaignModel } = useLatestEntity(initEntity, 'campaigns')
  const { id } = campaign

  const dispatch = useDispatch()

  const { loading, updating } = useSelector(reduxState => reduxState.campaigns)

  useReduxAction(
    'campaigns',
    'loadCampaign',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, reqOptions: EntityOptions) => action(id, reqOptions),
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { loadedIds } = entityReducer
        return performHttpRequests && !loadedIds?.includes(id)
      },
    },
  )

  const { callbacks } = useContext(PageContext)

  return {
    callbacks: {
      editStartDate: () => launchModal({
        callbacks,
        modalKey: 'EditStartDateModal',
        payload: { campaign },
      }),
      generateArtworksForCampaign: (options: GenerateArtworksForCampaignOptions) => (
        generateArtworksForCampaign({ campaign, dispatch, requestOptions: options })
      ),
      manageCampaignValidations: () => launchModal({
        callbacks,
        modalKey: 'ManageCampaignValidationsModal',
        payload: {
          campaign,
        },
      }),
      patchCampaign: (campaignParams: PatchCampaignModel, entityOptions?: CampaignRequestOptions) => (
        patchCampaign({
          campaign, campaignParams, dispatch, requestOptions: entityOptions,
        })
      ),
      updateCampaign: (campaignParams: Partial<CampaignModel>, entityOptions?: CampaignRequestOptions) => (
        updateCampaign({
          campaign, campaignParams, dispatch, requestOptions: entityOptions,
        })
      ),
      validateCampaign: () => validateCampaign({ campaign, dispatch }),
    },
    campaign,
    loading,
    updating,
    urls: generateUrls(campaign),
  }
}

export default useCampaign
