export type ProductModel = {
  assetGroupId?: number,
  basePrice: number,
  cacheKey: number,
  campaignTypeId?: number,
  description: string,
  id: number,
  isArchived: boolean,
  name: string,
  productLineId?: number,
}

export type ProductRequestOptions = {}

const state = {
  assetGroupId: null,
  basePrice: null,
  campaignTypeId: null,
  description: '',
  id: null,
  isArchived: false,
  name: '',
  productLineId: null,
}

export const requiredFields = [
  { key: 'description' },
  { key: 'name' },
  {
    key: 'basePrice',
    validation: (basePrice: number) => basePrice === undefined || basePrice > 0,
    invalidMessage: 'Invalid Price',
  },
]

export default state
