import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, DashboardModule, SectionDivider } from '@campaignhub/suit-theme'

import { categoriseAdAccountLinks } from '@functions/adAccountLink'

import useAdAccount from '@hooks/useAdAccount'
import useAdAccountLinks from '@hooks/useAdAccountLinks'
import useSelector from '@hooks/useSelector'

import { ClientModel } from '@models/types'

import AdAccountsModule from './AdAccountsModule'

interface AdAccountsProps {
  client: ClientModel,
}

const AdAccounts = (props: AdAccountsProps) => {
  const { client } = props

  const adAccountLinksPayload = useAdAccountLinks({
    filters: {
      linkableId: client.id,
      linkableType: 'Client',
    },
    performHttpRequests: !!client.id,
    requestOptions: {
      include: 'AdAccount',
    },
  })
  const { filteredAdAccountLinks, loading } = adAccountLinksPayload

  const { adAccounts } = useSelector(reduxState => reduxState.entities)

  const categorisedAdAccountLinks = categoriseAdAccountLinks({ adAccounts, adAccountLinks: filteredAdAccountLinks })
  const hasFacebookAdAccounts = !!categorisedAdAccountLinks.facebook.length
  const hasGoogleAdAccounts = !!categorisedAdAccountLinks.google.length

  const {
    callbacks: {
      launchSelectAdChannelModal,
    },
  } = useAdAccount()

	return (
		<>
      <SectionDivider>Ad Accounts</SectionDivider>

      {(!loading && !hasFacebookAdAccounts && !hasGoogleAdAccounts) && (
        <DashboardModule title="Ad Channels">
          <DashboardModule.Footer boxProps={{ borderTop: 'none' }}>
            <DashboardModule.FooterRight>
              <Button
                buttonStyle="secondaryEdit"
                disabled={!client.id}
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => launchSelectAdChannelModal({ linkableEntity: client, linkableType: 'Client' })}
                size={['medium', 'small']}
                width={['100%', 'fit-content']}
              >
                New Ad Channel
              </Button>
            </DashboardModule.FooterRight>
          </DashboardModule.Footer>
        </DashboardModule>
      )}

      {hasFacebookAdAccounts && (
        <AdAccountsModule
          adAccountLinks={categorisedAdAccountLinks.facebook}
          categoryTitle="facebook"
        />
      )}

      {hasGoogleAdAccounts && (
        <AdAccountsModule
          adAccountLinks={categorisedAdAccountLinks.google}
          categoryTitle="google"
        />
      )}

      {(hasFacebookAdAccounts || hasGoogleAdAccounts) && (
        <Box
          alignItems={['flex-start', 'center']}
          backgroundColor="white"
          border="1px solid"
          borderColor="lineColor"
          borderRadius={5}
          flexDirection={['column', 'row']}
          justifyContent="end"
          marginBottom="large"
          padding="large"
        >
          <Button
            buttonStyle="secondaryEdit"
            disabled={!client.id}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => launchSelectAdChannelModal({ linkableEntity: client, linkableType: 'Client' })}
            size={['medium', 'small']}
            width={['100%', 'fit-content']}
          >
            New Ad Channel
          </Button>
        </Box>
      )}
		</>
	)
}

export default AdAccounts