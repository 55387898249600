import { Helmet } from 'react-helmet-async'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-solid-svg-icons'

import { Box, Button, Columns, MainContent } from '@campaignhub/suit-theme'

import AdContent from '@components/AdContent'
import PageHeader from '@components/PageHeader'

import handleCallbackAction from '@functions/handleCallbackAction'

import useCampaign, { useCampaignForm, useRelations } from '@hooks/useCampaign'
import useLandingPage, { useLandingPageForm } from '@hooks/useLandingPage'

import CampaignDetails from '../CampaignDetails'
import CampaignInfo from '../CampaignInfo'
import FacebookAds from '../FacebookAds'

interface PageContentProps {
  campaignId: number,
  nestedNavigation?: boolean,
}

const PageContent = (props: PageContentProps) => {
  const { campaignId, nestedNavigation } = props

  const campaignPayload = useCampaign({ id: campaignId }, {
    performHttpRequests: true,
    requestOptions: {
      include: [
        'AdQueues',
        'Artworks',
        'Captions',
        'Project.DataStoreItems',
        'OrderItem.PriceListItem.Product'
      ],
    },
  })

  const {
    callbacks: {
      patchCampaign,
    },
    campaign,
    loading: loadingCampaign,
  } = campaignPayload

  const { landingPage } = useRelations(campaign)

  const campaignFormPayload = useCampaignForm(campaign, { validateOn: 'change' })
  const {
    entityState: {
      campaignTypeId,
      requiredAt,
    },
  } = campaignFormPayload

  const landingPagePayload = useLandingPage(landingPage)
  const {
    callbacks: {
      updateLandingPage,
    },
    loading: loadingLandingPage,
  } = landingPagePayload

  const landingPageFormPayload = useLandingPageForm(landingPage, { validateOn: 'change' })
  const { entityState: landingPageEntityState } = landingPageFormPayload

  const loading = loadingCampaign || loadingLandingPage

  const updateCampaignAndLandingPage = () => {
    const patchCampaignRequest = patchCampaign({ campaignTypeId, requiredAt })
    const updateLandingPageRequest = updateLandingPage(landingPageEntityState)

    return Promise.all([patchCampaignRequest, updateLandingPageRequest])
      .then(responses => {
        const hasError = responses.some(response => !response.success)

        const errors = responses.reduce((acc, value) => {
          const { errors } = value

          if (errors) {
            acc = [...acc, ...errors]
          }

          return acc
        }, [] as string[])

        if (hasError) throw new Error(errors[0])

        return ({ success: true })
      })
      .catch(errors => {
        const { message } = errors
        return { success: false, errors: [message] }
      })
  }

  const updateDigitalMarketingPayload = {
    callbacks: {
      action: updateCampaignAndLandingPage,
    },
    toastText: 'Digital Marketing Updated',
  }

  return (
    <>
      <Helmet>
        <title>Campaign Approval | AIM</title>
      </Helmet>

      <MainContent style={{ left: 0, width: '100%' }}>
        <PageHeader
          actionContent={(
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faSync} />}
              loading={loading}
              onClick={() => handleCallbackAction(updateDigitalMarketingPayload)}
              size="medium"
              width="auto"
            >
              Save and Refresh
            </Button>
          )}
          boxProps={{
            justifyContent: 'center',
            padding: 'xlarge',
            style: { left: nestedNavigation ?? 0 },
            width: 'calc(100% - 310px)',
          }}
          title="Digital Marketing Approval"
        />

        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              <CampaignInfo campaignPayload={campaignPayload} />

              <FacebookAds campaignPayload={campaignPayload} />
            </Columns.Main>

            <Columns.Sidebar>
              <CampaignDetails
                campaignPayload={campaignPayload}
                campaignFormPayload={campaignFormPayload}
                landingPageFormPayload={landingPageFormPayload}
              />

              <AdContent campaignPayload={campaignPayload} />
            </Columns.Sidebar>
          </Columns>
        </Box>
      </MainContent>
    </>
  )
}

export default PageContent